





























































import Vue from 'vue'
import { USERS_FETCH, NOTE_ADD, NOTE_DELETE } from '@/store/actions'
import { mapGetters } from 'vuex'
import NoteModel from '@/models/NoteModel'

export default Vue.extend({
  name: 'users',
  components: {
    // HelloWorld
  },
  mounted () {
    this.$store.dispatch(USERS_FETCH, {})
  },
  computed: {
    ...mapGetters(['users', 'isLoadingUsers'])
  },
  methods: {
    createNote () {
      if (this.noteText !== '') {
        this.$store.dispatch(NOTE_ADD, this.noteText)
        this.noteText = ''
      } else {
        console.warn('noteText is not allowed to be empty')
      }
    },
    deleteItem (item: NoteModel) {
      console.log(item)
      this.$store.dispatch(NOTE_DELETE, item.id)
    }
  },
  data: function () {
    return {
      search: '',
      noteText: '',
      itemsPerPage: 15,
      headers: [
        { text: this.$t('shared.user.name'), value: 'username' },
        { text: this.$t('shared.user.email'), value: 'email' },
        // { text: this.$t('shared.action'), value: 'actions', sortable: false, width: '5%', align: 'left' }
      ]
    }
  }
})
